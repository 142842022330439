<template>
    <b-overlay :show="loading">

        <b-card class="mb-2">
            <h4 class="mb-2">Informasi Retur</h4>
            <b-row class="mb-4">
                <b-col sm="12" md="6" class="mb-2">
                    <label for="">Pilih Jenis Retur</label>
                    <v-select :reduce="option => option.value" :options="[{value: 1, text: 'Rokok'}, {value: 2, text: 'Non-Rokok'}]" label="text" v-model="form.jenis"></v-select>
                </b-col>
                <b-col cols="6" class="mb-2">
                    <label for="">Tanggal Retur</label>
                    <b-form-input v-model="form.tanggal" type="date" class="w-full" />
                </b-col>
                <b-col cols="12">
                    <label for="">Keterangan (opsional)</label>
                    <b-form-textarea v-model="form.keterangan"></b-form-textarea>
                </b-col>
            </b-row>
        </b-card>
        <b-card>
            <h4 class="mb-2">Rincian Barang <span v-if="selectedBarangs.length > 0">({{selectedBarangs.length}})</span></h4>
            <b-button variant="primary" :disabled="!form.jenis" class="mb-3" @click.prevent="chooseBarang">Pilih Barang</b-button>
            <b-table v-if="selectedBarangs.length > 0" responsive :fields="fields" :items="selectedBarangs">
                <template #cell(no)="{index}">
                    {{(1 + index)}}
                </template>
                <template #cell(satuan)="{item}">
                    {{ item.satuan ? item.satuan.satuan : '' }}
                </template>
                <template #cell(total)="{item}">
                    {{ formatRupiah(parseInt(item.harga_beli) * parseInt(item.qty)) }}
                </template>
                <template #cell(action)="{item, index}">
                    <feather-icon icon="MinusCircleIcon" size="24" class="text-danger cursor-pointer" @click="removeBarang(item, index)"></feather-icon>
                </template>
            </b-table>
            <div v-else class="alert alert-danger p-2">
                <strong>Harap</strong> pilih barang rincian
            </div>
        </b-card>
        <barang-modal :barangs="stocks" @submit="onChooseBarang"></barang-modal>
        <div class="d-flex justify-content-end">
            <b-button :disabled="!isValidForm" @click.prevent="submit" :variant="!isValidForm ? 'secondary' : 'primary'">Simpan</b-button>
        </div>
    </b-overlay>
</template>
<script>
import BarangModal from './components/Modal.vue'
import vSelect from 'vue-select'
import {BCard, BButton, BRow, BOverlay, BTable, BCol, BFormInput, BFormSelect, BFormTextarea} from 'bootstrap-vue'
export default {
    components: {
        BarangModal, BCard, BOverlay, BButton, BTable, BRow, BCol, BFormInput, BFormSelect, BFormTextarea,
        vSelect
    },
    data:() => ({
        fields: [
            { key: 'no', label: 'No' },
            { key: 'nama', label: 'Nama Barang' },
            { key: 'qty', label: 'Qty' },
            { key: 'satuan', label: 'Satuan' },
            { key: 'action', label: '' }
        ],
        selectedBarangs: [],
        form: {
            tanggal: null,
            jenis: null,
            keterangan: null,
        },
        barangs: [],
        stocks: [],
        loading: false,
        hasPermissionApprove: false
    }),
    computed: {
        isKas() {
            return this.akun && this.akun.kategori && this.akun.kategori.kategori == 'Kas & Bank'
        },
        totalPembelian() {
            return this.selectedBarangs.reduce((total, barang) => total += ((barang.harga_beli * barang.qty) - barang.diskon),0)
        },
        isValidForm() {
            if(!this.form.tanggal || !this.form.jenis || this.selectedBarangs.length < 1) {
                return false
            }

            return true
        },
        updateMode() {
            if(this.$route.params.id) {
                return true
            }
            return false
        }
    },
    watch: {
        async "form.jenis"(jenis) {
            if(jenis) {
                this.loading = true
                await this.getBarang()
                this.loading = false
            }
        }
    },
    methods: {
        async checkPermission() {
            const menu = await this.currentMenu(this.$route.meta.parent)
            const params = {
                level_id: this.user.level.id
            }
            if(menu) {
                params.menu_id = menu.id
            }
            this.listStatus = await this.$store.dispatch('statusrole/getData', params)

            // data_status => 1 approve gm, data_status => 2 pay finance
            const hasPermissionApprove = this.listStatus.some(status => status.data_status == 1)
            
            this.hasPermissionApprove = hasPermissionApprove
        },
        async submit() {
            if(!this.isValidForm) {
                this.displayError({
                    message: 'Harap lengkapi form pembelian!'
                })
                return false
            }

            const payload = this.form

            if(this.updateMode) {
                payload.id = this.$route.params.id
            }
            
            let payloadBarang = []
            // make payload item rincian barang
            this.selectedBarangs.map(stock => {
                const payload = {
                    retur_id: null,
                    penyimpanan_id: stock.penyimpanan_id,
                    qty: parseInt(stock.qty)
                }
                if(stock.rincian_id) {
                    payload.id = stock.rincian_id
                }
                payloadBarang.push(payload)
            })

            // check jika ada harga dasar baru, apakah ingin update harga dasar baru
            try {
                this.loading = true
                const retur = await this.$store.dispatch('retur-supplier/save', [payload])
                payloadBarang.map(barang => {
                    barang.retur_id = retur.id
                })
                await this.$store.dispatch('retur-supplier/saveRincian', payloadBarang)

                this.loading = false
                this.displaySuccess({
                    message: 'Retur Supplier berhasil disimpan'
                })

                setTimeout(() => this.$router.push('/retur-supplier'), 1000)
            }
            catch(e) {
                this.loading = false
                this.displayError(e)
                return false
            }

        },
        updateBasePrice() {
            this.$swal({
                title: 'Update harga dasar?',
                text: 'Harga dasar barang yang anda pilih akan diupdate!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ya',
                cancelButtonText: 'Batal',
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-1',
                }
            })
            .then(res => {
                if(res.value) {
                    const barangs = this.selectedBarangs.filter(barang => barang.isChangePrice)
                    const payloads = barangs.map(barang => ({
                        id: barang.id,
                        kode: barang.kode,
                        nama: barang.nama,
                        varian: barang.varian,
                        jenis: barang.jenis,
                        id_kategori: barang.kategori ? barang.kategori.id : null,
                        id_satuan: barang.satuan ? barang.satuan.id : null,
                        harga_dasar: barang.harga_dasar_baru
                    }))

                    this.loading = true
                    this.$store.dispatch('barang/save', payloads)
                    .then(() => {
                        this.loading = false
                        setTimeout(() => this.$router.push('/pembelian'), 500)
                    })
                    .catch(e => {
                        this.loading = false
                        this.displayError(e)
                        return false
                    })
                }
                else {
                    setTimeout(() => this.$router.push('/pembelian'), 500)
                }
            })
        },
        removeBarang(item, index) {
            if(!item.rincian_id) {
                this.selectedBarangs.splice(index, 1)
            }
            else {
                // remove via API
                const payload = {
                    id: item.rincian_id,
                    fungsi: 2
                }
                this.$store.dispatch('retur-supplier/saveRincian', [payload])
                .then(() => {
                    this.selectedBarangs.splice(index, 1)
                    this.getBarang()
                })
                .catch(e => {
                    this.displayError(e)
                    return false
                })
            }
        },
        onChooseBarang(selectedBarangs) {
            selectedBarangs.map(stock => {

                const payload = {
                    nama: stock.barang ? stock.barang.nama : null,
                    penyimpanan_id: stock.id,
                    qty: stock.qty,
                    satuan: stock.barang && stock.barang.satuan ? stock.barang.satuan : null
                }
                this.selectedBarangs.push(payload)
            })
            this.$bvModal.hide('barang-modal')
        },
        chooseBarang() {
            this.$bvModal.show('barang-modal')
        },
        async getBarang() {
            // const jenis = this.form.jenis
            // const params = { jenis }
            // let anu = await this.$store.dispatch('barang/getDataV2', params)
            // anu = this.barangs

            // // get stok barang
            // const barangs_id = anu.map(barang => barang.id)
            // const stockParams = {order: 'desc', barang_id: barangs_id.join(',')}
            // if(this.myGudang) stockParams.gudang_id = this.myGudang.id
            // const stocks = await this.$store.dispatch('penyimpanan/getData', stockParams)
            const stocks = await this.$store.dispatch('penyimpanan/getData')
            this.stocks = stocks
        },
    },
    async created() {
        await this.checkPermission()
        
        this.form.tanggal = this.getCurrentDate()
        if(this.updateMode) {
            const returs = this.$store.state['retur-supplier'].datas
            const retur = returs.find(item => item.id == this.$route.params.id)
            if(!retur) {
                this.$router.push('/retur-supplier')
            }

            this.form.tanggal = retur.tanggal
            this.form.jenis = retur.jenis
            this.form.keterangan = retur.keterangan
            retur.rincian.map(rincian => {
                if(rincian.barang) {
                    const {barang} = rincian
                    const item = {
                        rincian_id: rincian.id,
                        penyimpanan_id: rincian.penyimpanan_id,
                        nama: barang.nama,
                        satuan: barang.satuan,
                        qty: rincian.qty
                    }
                    this.selectedBarangs.push(item)
                }
            })
        }
    }
}
</script>