var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-card', {
    staticClass: "mb-2"
  }, [_c('h4', {
    staticClass: "mb-2"
  }, [_vm._v("Informasi Retur")]), _c('b-row', {
    staticClass: "mb-4"
  }, [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Pilih Jenis Retur")]), _c('v-select', {
    attrs: {
      "reduce": function (option) {
        return option.value;
      },
      "options": [{
        value: 1,
        text: 'Rokok'
      }, {
        value: 2,
        text: 'Non-Rokok'
      }],
      "label": "text"
    },
    model: {
      value: _vm.form.jenis,
      callback: function ($$v) {
        _vm.$set(_vm.form, "jenis", $$v);
      },
      expression: "form.jenis"
    }
  })], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "6"
    }
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Tanggal Retur")]), _c('b-form-input', {
    staticClass: "w-full",
    attrs: {
      "type": "date"
    },
    model: {
      value: _vm.form.tanggal,
      callback: function ($$v) {
        _vm.$set(_vm.form, "tanggal", $$v);
      },
      expression: "form.tanggal"
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Keterangan (opsional)")]), _c('b-form-textarea', {
    model: {
      value: _vm.form.keterangan,
      callback: function ($$v) {
        _vm.$set(_vm.form, "keterangan", $$v);
      },
      expression: "form.keterangan"
    }
  })], 1)], 1)], 1), _c('b-card', [_c('h4', {
    staticClass: "mb-2"
  }, [_vm._v("Rincian Barang "), _vm.selectedBarangs.length > 0 ? _c('span', [_vm._v("(" + _vm._s(_vm.selectedBarangs.length) + ")")]) : _vm._e()]), _c('b-button', {
    staticClass: "mb-3",
    attrs: {
      "variant": "primary",
      "disabled": !_vm.form.jenis
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.chooseBarang($event);
      }
    }
  }, [_vm._v("Pilih Barang")]), _vm.selectedBarangs.length > 0 ? _c('b-table', {
    attrs: {
      "responsive": "",
      "fields": _vm.fields,
      "items": _vm.selectedBarangs
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function (_ref) {
        var index = _ref.index;
        return [_vm._v(" " + _vm._s(1 + index) + " ")];
      }
    }, {
      key: "cell(satuan)",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.satuan ? item.satuan.satuan : '') + " ")];
      }
    }, {
      key: "cell(total)",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(_vm.formatRupiah(parseInt(item.harga_beli) * parseInt(item.qty))) + " ")];
      }
    }, {
      key: "cell(action)",
      fn: function (_ref4) {
        var item = _ref4.item,
          index = _ref4.index;
        return [_c('feather-icon', {
          staticClass: "text-danger cursor-pointer",
          attrs: {
            "icon": "MinusCircleIcon",
            "size": "24"
          },
          on: {
            "click": function ($event) {
              return _vm.removeBarang(item, index);
            }
          }
        })];
      }
    }], null, false, 2214647420)
  }) : _c('div', {
    staticClass: "alert alert-danger p-2"
  }, [_c('strong', [_vm._v("Harap")]), _vm._v(" pilih barang rincian ")])], 1), _c('barang-modal', {
    attrs: {
      "barangs": _vm.stocks
    },
    on: {
      "submit": _vm.onChooseBarang
    }
  }), _c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('b-button', {
    attrs: {
      "disabled": !_vm.isValidForm,
      "variant": !_vm.isValidForm ? 'secondary' : 'primary'
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.submit($event);
      }
    }
  }, [_vm._v("Simpan")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }